/*!
 * Start Bootstrap - Blog Post (https://startbootstrap.com/templates/blog-post)
 * Copyright 2013-2019 Start Bootstrap
 * Licensed under MIT (https://github.com/StartBootstrap/startbootstrap-blog-post/blob/master/LICENSE)
 */
body {
  padding-top: 5em;
}

.navbar-custom {
  background-color: #540000 !important;
  position: absolute;
  display:block;
  min-height: 5em;
  max-height: 5em;
}

.nav-link {
  color: #DBD3B2 !important;
}

.expanded-navbar {
  background-color: #540000 !important;
  padding-left: 2em;
  padding-top: 1em;
  padding-bottom: 1em;
  border-radius: 25px;
  margin-left: 30vw;
}

.carousel-item {
  max-height: 50vh;
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

}

.transparentTitle {
  background-color: rgba(255,255,255,0.5);
  color: black
}

.carousel-inner img {
  margin: auto;
}

header {
  margin-bottom: 25px;
}

.active {
  border-bottom: 3px solid #DBD3B2;
}

.card-sub-title{
  padding-top: .5vh;
  padding-bottom: .5vh;
  min-height: 1vh;
  max-height: 1vh;
}

.card-title {
  min-height: 8vh;
  max-height: 8vh;
  word-wrap: break-word;
  overflow:hidden;
}

.news-card-title {
  min-height: 8vh;
  word-wrap: break-word;
  overflow:hidden;
}

.news-card-text {
  min-height: 20vh;
  word-wrap: break-word;
  overflow:hidden;
}

.card-text {
  min-height: 20vh;
  max-height: 20vh;
  word-wrap: break-word;
  overflow:hidden;
}

div.DraftEditor-editorContainer {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: .25rem;
  overflow-y: auto;
  padding-left: 1em;
  padding-right: 1em;
}

div.rdw-editor-toolbar {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: .25rem;
  overflow-y: auto;
}